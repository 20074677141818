// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl : 'https://api.feature.eris.efda.gov.et/api/',
  appUrl: 'https://eris.staging.efda.gov.et/',
  iLicenseUrl: 'https://ilicense.staging.efda.gov.et',
  iInspectUrl: 'https://ilicense.staging.efda.gov.et/inspections/preInspection/list',
  rapidAlertUrl: 'https://dev.ras.k8s.sandboxaddis.com',
  iLicenseApiUrl: 'https://ilicense.staging.api.efda.gov.et/api/',
  production: true,
  version: '3.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
